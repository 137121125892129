import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
// import Cables from "../components/cables"
// import CallToAction from "../components/cta"
import SEO from "../components/seo"

const Template = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  
  return (
    <Layout>
      <SEO title="Blog" />
      <div className="max-w-3xl pt-4 mx-auto">
        <div className="max-w-xl mx-4">
          <h1 className="mb-8 font-semibold leading-10 text-black">
            {frontmatter.title}
          </h1>
          <p className=""></p>
          {frontmatter.summary && 
          <p className="mb-8 text-lg">
            {frontmatter.summary}
          </p>
          }
          <div className="audyem-blogposts mb-8 md:mb-16 text-gray-700" dangerouslySetInnerHTML={{ __html: html }}>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Template
export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        summary
      }
    }
  }
`